<template>
  <div id="under-construction">
    <h1>Kinzed | Under Construction</h1>
    <p>I used to be an automotive engineer, but I have since resigned to focus on my own project. I am developing a powerful tool to enhance your Simulink development experience. Additionally, I am building this website and documenting the process from a beginner's perspective.</p>
  </div>
</template>

<script>
export default {
  name: 'UnderConstruction'
}
</script>

<style>
#under-construction {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>